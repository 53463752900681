import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Stack, FormGroup, Divider, FormLabel } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { revisoftLogo, welcomeImage } from "../../../assets/image";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import logo from "assets/image/revisoft_logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "../../../constants/apiStatusCode";
import defaultString from "../../../constants/defaultString.json";
import { getDataFromStorage } from "storage/StorageData";
import dayjs from "dayjs";
import { API } from "service/AxiosService";
import { useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { itIT } from "@mui/x-date-pickers/locales";
import { resetToken } from "apiURL/commonFunctions";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import styles from "./styles";
import { RegistriIVATable } from "components/RegistriIVATable";

function createData(dataRegistrazione, conto, importoDare, importoAvere, nota) {
  return {
    dataRegistrazione,
    conto,
    importoDare,
    importoAvere,
    nota,
  };
}

const rows = [
  createData(
    "11/01/1991",
    "04 - Conto anticipi",
    "999.999.999,00",
    "999.999.999,00 ",
    "Nota bla baladkf "
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.data.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pt: { sm: 1 },
        pb: { sm: 1 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected == 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionato
        </Typography>
      ) : numSelected > 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionati
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <TextField
            id="outlined-basic"
            label="Cerca"
            variant="outlined"
            sx={{ width: "25pc" }}
          />
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [dense, setDense] = React.useState(false);
  const { i18n, t } = useTranslation();
  const [venditaChecked, setVenditaChecked] = useState(false);
  const [acquistiChecked, setAcquistiChecked] = useState(false);
  const [rvrseVenditaChecked, setRvrseVenditaChecked] = useState(false);
  const [rvrseAcquistiChecked, setRvrseAcquistiChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [venditaRows, setVenditaRows] = useState([]);
  const [acquistiRows, setAcquistiRows] = useState([]);
  const [rvrseVenditaRows, setRvrseVenditaRows] = useState([]);
  const [rvrseAcquistiRows, setRvrseAcquistiRows] = useState([]);

  const [ttlSalesTaxabale, setTtlSalesTaxabale] = useState(0);
  const [ttlSalesVatAmt, setTtlSalesVatAmt] = useState(0);
  const [ttlSalesV17Amt, setTtlSalesV17Amt] = useState(0);
  const [ttlSalesAmt, setTtlSalesAmt] = useState(0);

  const [ttlTaxableIncomeAcquisti, setTtlTaxableIncomeAcquisti] = useState(0);
  const [ttlNondeductibleVatAcquisti, setTtlNondeductibleVatAcquisti] =
    useState(0);
  const [ttlAcquistiV17Amt, setTtlAcquistiV17Amt] = useState(0);
  const [ttlAcquistiAmt, setTtlAcquistiAmt] = useState(0);

  const [ttlRvrseTaxableIncomeAcquisti, setTtlRvrseTaxableIncomeAcquisti] =
    useState(0);
  const [
    ttlRvrseNondeductibleVatAcquisti,
    setTtlRvrseNondeductibleVatAcquisti,
  ] = useState(0);
  const [ttlRvrseAcquistiV17Amt, setTtlRvrseAcquistiV17Amt] = useState(0);
  const [ttlRvrseAcquistiAmt, setTtlRvrseAcquistiAmt] = useState(0);

  const [ttlRvrseTaxableIncomeSales, setTtlRvrseTaxableIncomeSales] =
    useState(0);
  const [ttlRvrseSalesV17Amt, setTtlRvrseSalesV17Amt] = useState(0);
  const [ttlRvrseSalesAmt, setTtlRvrseSalesAmt] = useState(0);

  const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM"));
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    selectionDate: Yup.date().required(t("selectionDateRequired")),
  });
  const formik = useFormik({
    initialValues: {
      selectionDate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
      navigate("/", { state: { isModalPopup: true } });
    }
  };

  const getRegitraIVAReport = async () => {
    setIsLoading(true);
    const accountingSubject = await getDataFromStorage("accountingSubject");
    // setClientName(accountingSubject.name);
    // setVatNumber(accountingSubject.vatNumber);
    const clientName = accountingSubject.name;
    const vatNumber = accountingSubject.vatNumber;
    if (!!formik?.values) {
      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.selectionDate = dayjs(selectedDate).format("YYYY-MM-DD");
      requestObject.isSalesSelected = venditaChecked;
      requestObject.isPurchaseSelected = acquistiChecked;
      requestObject.isReversePurchaseSelected = rvrseAcquistiChecked;
      requestObject.isReverseSaleSelected = rvrseVenditaChecked;

      API.post(apiURL.service_registriIVA_report, requestObject)
        .then(async (res) => {
          setIsLoading(false);
          if (res?.status === apiStatusCode.SUCCESS) {
            if (
              (res?.data?.data?.report?.salesData == undefined ||
                res?.data?.data?.report?.salesData.length == 0) &&
              (res?.data?.data?.report?.purchaseData == undefined ||
                res?.data?.data?.report?.purchaseData.length == 0) &&
              (res?.data?.data?.report?.reversePurchaseData == undefined ||
                res?.data?.data?.report?.reversePurchaseData.length == 0) &&
              (res?.data?.data?.report?.reverseSalesData == undefined ||
                res?.data?.data?.report?.reverseSalesData.length == 0)
            ) {
              setOpen(true);
              setSeverity("error");
              setErrorMsg(t("noRecordFound"));
            } else {
              await generatePDFDocument(
                res?.data?.data?.report?.salesData,
                res?.data?.data?.report?.purchaseData,
                res?.data?.data?.report?.reversePurchaseData,
                res?.data?.data?.report?.reverseSalesData,
                clientName,
                vatNumber
              );
            }

            setVenditaRows(res?.data?.data?.report?.salesData);
            setAcquistiRows(res?.data?.data?.report?.purchaseData);
            setRvrseVenditaRows(res?.data?.data?.report?.reverseSalesData);
            setRvrseAcquistiRows(res?.data?.data?.report?.reversePurchaseData);
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setOpen(true);
          setSeverity("error");
          setIsLoading(false);
          if (error?.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error?.code === defaultString.bad_request) {
            if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
              // setErrorMsg(t("noRecordFound"))
            } else if (
              error?.response?.data?.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const callApiAndPdf = async () => {
    const data = await getRegitraIVAReport();
  };

  const TableHeaderVendita = () => {
    return (
      <View style={styles.viewMainContainer}>
        <View style={styles.flexCommon}>
          <Text style={styles.textHeader}>{t("dataReg.")}</Text>
        </View>
        <View style={[styles.flexCommonLeftLarge, { flex: 1.41 }]}>
          <Text style={[styles.textHeader, { flex: 1 }]}>{`${t(
            "fattura"
          )} - ${t("documento")}`}</Text>
          <View style={styles.viewHorizonatlDoubled}>
            <View style={[styles.flexCommonInside, { flex: 1 }]}>
              <Text style={styles.textHeader}>{t("no")}</Text>
            </View>
            <View
              style={[
                styles.flexCommonInside,
                { flex: 1, borderLeftWidth: "1px", borderColor: "black" },
              ]}
            >
              <Text style={styles.textHeader}>{t("data")}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.flexDouble, { flex: 3.2 }]}>
          <Text style={styles.textHeader}>{`${t("cliente")} - ${t(
            "partita"
          )} ${t("iva")}`}</Text>
        </View>
        <View style={styles.viewRowList}>
          <View style={styles.viewRowListItem}>
            <View style={styles.flexCommon}>
              <Text style={styles.textHeader}>{t("imponibile")}</Text>
            </View>
            <View style={[styles.flexCommonLeftLarge, { flex: 1.4 }]}>
              <Text style={[styles.textHeader, { flex: 1 }]}>{t("iva")}</Text>
              <View style={styles.viewHorizonatlDoubled}>
                <View style={[styles.flexCommonInside, { flex: 0.7 }]}>
                  <Text style={styles.textHeader}>{t("%")}</Text>
                </View>
                <View
                  style={[
                    styles.flexCommonInside,
                    { borderLeftWidth: "1px", borderColor: "black" },
                  ]}
                >
                  <Text style={styles.textHeader}>{t("importo")}</Text>
                </View>
              </View>
            </View>
            <View style={styles.flexCommon}>
              <Text style={styles.textHeader}>
                {`Split payment\nArt. 17 633/72`}
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.flexCommon, { borderRightWidth: "1px" }]}>
          <Text style={styles.textHeader}>{t("totale")}</Text>
        </View>
      </View>
    );
  };

  const TableHeaderAcquisti = () => {
    return (
      <View style={styles.viewMainContainer}>
        <View style={styles.flexCommon}>
          <Text style={styles.textHeader}>{t("dataReg.")}</Text>
        </View>
        <View style={[styles.flexCommonLeftLarge, { flex: 2.1 }]}>
          <Text style={[styles.textHeader, { flex: 1 }]}>{`${t(
            "fattura"
          )} - ${t("documento")}`}</Text>
          <View style={styles.viewHorizonatlDoubled}>
            <View style={[styles.flexCommonInside, { flex: 1 }]}>
              <Text style={styles.textHeader}>{t("no")}</Text>
            </View>
            <View
              style={[
                styles.flexCommonInside,
                { flex: 1, borderLeftWidth: "1px", borderColor: "black" },
              ]}
            >
              <Text style={styles.textHeader}>{t("data")}</Text>
            </View>
            <View
              style={[
                styles.flexCommonInside,
                { flex: 1, borderLeftWidth: "1px", borderColor: "black" },
              ]}
            >
              <Text style={styles.textHeader}>{t("prot")}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.flexDouble, { flex: 3.2 }]}>
          <Text style={styles.textHeader}>
            {t("fornitori") + " " + "-" + " " + t("partita") + " " + t("iva")}
          </Text>
        </View>
        <View style={[styles.viewRowList]}>
          <View style={styles.viewRowListItem}>
            <View style={styles.flexCommon}>
              <Text style={styles.textHeader}>{t("imponibile")}</Text>
            </View>
            <View style={[styles.flexCommonLeftLarge, { flex: 1.4 }]}>
              <Text style={[styles.textHeader, { flex: 1 }]}>
                {t("ivaDetraibile")}
              </Text>
              <View style={styles.viewHorizonatlDoubled}>
                <View style={[styles.flexCommonInside, { flex: 0.7 }]}>
                  <Text style={styles.textHeader}>{t("%")}</Text>
                </View>
                <View
                  style={[
                    styles.flexCommonInside,
                    { borderLeftWidth: "1px", borderColor: "black", flex: 0.7 },
                  ]}
                >
                  <Text style={styles.textHeader}>{t("importo")}</Text>
                </View>
              </View>
            </View>
            <View style={styles.flexCommon}>
              <Text style={styles.textHeader}>{t("ivaInDetraibile")}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.flexCommon, { borderRightWidth: "1px" }]}>
          <Text style={styles.textHeader}>{t("totale")}</Text>
        </View>
      </View>
    );
  };

  const TableHeaderRvrseAcquistiRows = () => {
    return (
      <View style={styles.viewMainContainer}>
        <View style={styles.flexCommon}>
          <Text style={styles.textHeader}>{t("dataReg.")}</Text>
        </View>
        <View style={[styles.flexCommonLeftLarge, { flex: 2.1 }]}>
          <Text style={[styles.textHeader, { flex: 1 }]}>{`${t(
            "fattura"
          )} - ${t("documento")}`}</Text>
          <View style={styles.viewHorizonatlDoubled}>
            <View style={[styles.flexCommonInside, { flex: 1 }]}>
              <Text style={styles.textHeader}>{t("no")}</Text>
            </View>
            <View
              style={[
                styles.flexCommonInside,
                { flex: 1, borderLeftWidth: "1px", borderColor: "black" },
              ]}
            >
              <Text style={styles.textHeader}>{t("data")}</Text>
            </View>
            <View
              style={[
                styles.flexCommonInside,
                { flex: 1, borderLeftWidth: "1px", borderColor: "black" },
              ]}
            >
              <Text style={styles.textHeader}>{t("prot")}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.flexDouble, { flex: 3.2 }]}>
          <Text style={styles.textHeader}>
            {t("fornitori") + " " + "-" + " " + t("partita") + " " + t("iva")}
          </Text>
        </View>
        <View style={styles.viewRowList}>
          <View style={styles.viewRowListItem}>
            <View style={styles.flexCommon}>
              <Text style={styles.textHeader}>{t("imponibile")}</Text>
            </View>
            <View style={[styles.flexCommonLeftLarge, { flex: 1.4 }]}>
              <Text style={[styles.textHeader, { flex: 1 }]}>
                {t("ivaDetraibile")}
              </Text>
              <View style={styles.viewHorizonatlDoubled}>
                <View style={[styles.flexCommonInside, { flex: 0.7 }]}>
                  <Text style={styles.textHeader}>{t("%")}</Text>
                </View>
                <View
                  style={[
                    styles.flexCommonInside,
                    { borderLeftWidth: "1px", borderColor: "black", flex: 0.7 },
                  ]}
                >
                  <Text style={styles.textHeader}>{t("importo")}</Text>
                </View>
              </View>
            </View>
            <View style={styles.flexCommon}>
              <Text style={styles.textHeader}>{t("ivaInDetraibile")}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.flexCommon, { borderRightWidth: "1px" }]}>
          <Text style={styles.textHeader}>{t("totale")}</Text>
        </View>
      </View>
    );
  };

  const TableHeaderRvrseVenditaRows = () => {
    return (
      <View style={styles.viewMainContainer}>
        <View style={styles.flexCommon}>
          <Text style={styles.textHeader}>{t("dataReg.")}</Text>
        </View>
        <View style={[styles.flexCommonLeftLarge, { flex: 2.1 }]}>
          <Text style={[styles.textHeader, { flex: 1 }]}>{`${t(
            "fattura"
          )} - ${t("documento")}`}</Text>
          <View style={styles.viewHorizonatlDoubled}>
            <View style={[styles.flexCommonInside, { flex: 1 }]}>
              <Text style={styles.textHeader}>{t("no")}</Text>
            </View>
            <View
              style={[
                styles.flexCommonInside,
                { flex: 1, borderLeftWidth: "1px", borderColor: "black" },
              ]}
            >
              <Text style={styles.textHeader}>{t("data")}</Text>
            </View>
            <View
              style={[
                styles.flexCommonInside,
                { flex: 1, borderLeftWidth: "1px", borderColor: "black" },
              ]}
            >
              <Text style={styles.textHeader}>{`Prot.`}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.flexDouble, { flex: 3.2 }]}>
          <Text style={styles.textHeader}>
            {t("cliente") +
              "/" +
              t("fornitori") +
              " " +
              "-" +
              " " +
              t("partita") +
              " " +
              t("iva")}
          </Text>
        </View>
        <View style={styles.viewRowList}>
          <View style={styles.viewRowListItem}>
            <View style={styles.flexCommon}>
              <Text style={styles.textHeader}>{t("imponibile")}</Text>
            </View>
            <View style={[styles.flexCommonLeftLarge, { flex: 1.4 }]}>
              <Text style={[styles.textHeader, { flex: 1 }]}>
                {t("ivaDetraibile")}
              </Text>
              <View style={styles.viewHorizonatlDoubled}>
                <View style={[styles.flexCommonInside, { flex: 0.7 }]}>
                  <Text style={styles.textHeader}>{t("%")}</Text>
                </View>
                <View
                  style={[
                    styles.flexCommonInside,
                    { borderLeftWidth: "1px", borderColor: "black" },
                  ]}
                >
                  <Text style={styles.textHeader}>{t("importo")}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.flexCommon, { borderRightWidth: "1px" }]}>
          <Text style={styles.textHeader}>{t("totale")}</Text>
        </View>
      </View>
    );
  };

  const generatePDFDocument = async (
    venditaRows,
    acquistiRows,
    rvrseAcquistiRows,
    rvrseVenditaRows,
    clientName,
    vatNumber
  ) => {
    let totalTaxableIncome = 0;
    let totalVatAmount = 0;
    let totalV17Amt = 0;
    let totalSalesAmt = 0;

    let totalTaxableIncomeAcquisti = 0;
    let totalNondeductibleVatAcquisti = 0;
    let totalVatAmountAcquisti = 0;
    let totalAcquistiAmt = 0;

    let totalRvrseTaxableIncomeAcquisti = 0;
    let totalRvrseNondeductibleVatAcquisti = 0;
    let totalRvrseVatAmountAcquisti = 0;
    let totalRvrseAcquistiAmt = 0;

    let totalRvrseTaxableIncomeSales = 0;
    let totalRvrseVatAmountSales = 0;
    let totalRvrseSalesAmt = 0;

    venditaRows &&
      venditaRows.forEach((row) => {
        totalSalesAmt += row.total;
        row.vats.forEach((vats, i) => {
          totalTaxableIncome += vats.taxableIncome;
          if (vats.code != "V 17 ter") {
            totalVatAmount += vats.vatAmount;
          }

          if (vats.code == "V 17 ter") {
            totalV17Amt += vats.vatAmount;
          }
        });
      });

    acquistiRows &&
      acquistiRows.forEach((row) => {
        totalAcquistiAmt += row.total;
        row.vats.forEach((vats, i) => {
          totalTaxableIncomeAcquisti += vats.taxableIncome;
          totalVatAmountAcquisti += vats.vatAmount;
          totalNondeductibleVatAcquisti += vats.nonDeductibleVat;
        });
      });

    rvrseAcquistiRows &&
      rvrseAcquistiRows.forEach((row) => {
        totalRvrseAcquistiAmt += row.total;
        row.vats.forEach((vats, i) => {
          totalRvrseTaxableIncomeAcquisti += vats.taxableIncome;
          totalRvrseVatAmountAcquisti += vats.vatAmount;
          totalRvrseNondeductibleVatAcquisti += vats.nonDeductibleVat;
        });
      });

    rvrseVenditaRows &&
      rvrseVenditaRows.forEach((row) => {
        totalRvrseSalesAmt += row.total;
        row.vats.forEach((vats, i) => {
          totalRvrseTaxableIncomeSales += vats.taxableIncome;
          totalRvrseVatAmountSales += vats.vatAmount;
        });
      });

    const blob = await pdf(
      <Document>
        <Page size="A3" orientation="landscape" style={{ padding: "24px " }}>
          <View fixed>
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              {/* <View
                style={{
                  width: "70%",
                }}
              >
                <Image src={logo} style={{ width: "240px" }} />
              </View> */}
              <View
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    textTransform: "uppercase",
                  }}
                >
                  {t("soggetto") + " " + t("contabile")} : {""}
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  >
                    {clientName} - {vatNumber}
                  </Text>
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    marginRight: "16px",
                    alignSelf: "baseline",
                  }}
                >
                  {t("printDate")}
                </Text>

                <Text
                  style={{
                    fontSize: "10px",
                    alignSelf: "baseline",
                  }}
                >
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>
          </View>

          {/* Table 1 */}
          {venditaRows && venditaRows.length > 0 && (
            <View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "brown",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("registriIVAVendita")}
                  </Text>
                </View>
              </View>

              <View>
                <TableHeaderVendita />
                <View style={styles.viewBottomBorder}>
                  {venditaRows.length > 0
                    ? venditaRows.map((item, index) => (
                        <RegistriIVATable
                          registrationDate={dayjs(
                            item?.registrationDate
                          ).format("DD/MM/YYYY")}
                          invoiceNo={item.invoiceNo}
                          documentDate={dayjs(item.documentDate).format(
                            "DD/MM/YYYY"
                          )}
                          cliente={`${item?.clientName} - ${item?.clientVatNo}`}
                          vats={item.vats}
                          total={
                            item.total
                              ? item?.total?.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : null
                          }
                          venditaRows={venditaRows}
                        />
                      ))
                    : null}
                </View>

                <View style={styles.totalView}>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderLeftWidth: "none",
                        borderTopWidth: "none",
                        borderBottomWidth: "none",
                      },
                    ]}
                  >
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderLeftWidth: "none",
                        borderTopWidth: "none",
                        borderBottomWidth: "none",
                      },
                    ]}
                  >
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderLeftWidth: "none",
                        borderTopWidth: "none",
                        borderBottomWidth: "none",
                      },
                    ]}
                  >
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View
                    style={[
                      styles.flexDouble,
                      { flex: 3.0695, border: "none", alignItems: "none" },
                    ]}
                  >
                    <Text style={[styles.totalText, { textAlign: "right" }]}>
                      totale
                    </Text>
                  </View>

                  <View style={[styles.viewRowList, { flex: 2.7175 }]}>
                    <View style={styles.viewRowListItem}>
                      <View
                        style={[
                          styles.flexCommon,
                          {
                            flex: 0.69,
                            borderTopWidth: "none",
                            alignItems: "none",
                          },
                        ]}
                      >
                        <Text
                          style={[styles.totalText, { textAlign: "right" }]}
                        >
                          {totalTaxableIncome == 0
                            ? null
                            : totalTaxableIncome.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.flexCommon,
                          {
                            flex: 0.687,
                            borderTopWidth: "none",
                            alignItems: "none",
                          },
                        ]}
                      >
                        <Text
                          style={[styles.totalText, { textAlign: "right" }]}
                        ></Text>
                      </View>
                      <View
                        style={[
                          styles.flexCommon,
                          {
                            flex: 0.685,
                            borderTopWidth: "none",
                            alignItems: "none",
                          },
                        ]}
                      >
                        <Text
                          style={[styles.totalText, { textAlign: "right" }]}
                        >
                          {totalVatAmount == 0
                            ? null
                            : totalVatAmount.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.flexCommon,
                          {
                            flex: 0.689,
                            borderTopWidth: "none",
                            alignItems: "none",
                          },
                        ]}
                      >
                        <Text
                          style={[styles.totalText, { textAlign: "right" }]}
                        >
                          {totalV17Amt == 0
                            ? null
                            : totalV17Amt.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderTopWidth: "none",
                        borderRightWidth: "1px",
                        flex: 0.68,
                        alignItems: "none",
                      },
                    ]}
                  >
                    <Text style={[styles.totalText, { textAlign: "right" }]}>
                      {totalSalesAmt == 0
                        ? null
                        : totalSalesAmt.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* Table 2 */}
          {acquistiRows && acquistiRows.length > 0 && (
            <View>
              <View>
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "brown",
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {t("registraIvaAcquisti")}
                    </Text>
                  </View>
                </View>
              </View>

              <View>
                <TableHeaderAcquisti />

                <View style={styles.viewBottomBorder}>
                  {acquistiRows &&
                    acquistiRows.map((item, index) => (
                      <RegistriIVATable
                        registrationDate={dayjs(item?.registrationDate).format(
                          "DD/MM/YYYY"
                        )}
                        invoiceNo={item.invoiceNo}
                        protocol={item.protocol}
                        documentDate={dayjs(item.documentDate).format(
                          "DD/MM/YYYY"
                        )}
                        supplier={`${item.supplierName} - ${item.supplierVatNo}`}
                        vats={item.vats}
                        total={
                          item.total
                            ? item?.total?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : null
                        }
                        acquistiRows={acquistiRows}
                      />
                    ))}
                </View>

                <View style={styles.totalView}>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderLeftWidth: "none",
                        borderTopWidth: "none",
                        borderBottomWidth: "none",
                      },
                    ]}
                  >
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderLeftWidth: "none",
                        borderTopWidth: "none",
                        borderBottomWidth: "none",
                      },
                    ]}
                  >
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderLeftWidth: "none",
                        borderTopWidth: "none",
                        borderBottomWidth: "none",
                      },
                    ]}
                  >
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderLeftWidth: "none",
                        borderTopWidth: "none",
                        borderBottomWidth: "none",
                      },
                    ]}
                  >
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View
                    style={{
                      flex: 3.065,
                      border: "none",
                      justifyContent: "right",
                    }}
                  >
                    <Text style={[styles.totalText, { textAlign: "right" }]}>
                      totale
                    </Text>
                  </View>

                  <View style={[styles.viewRowList, { flex: 2.724 }]}>
                    <View style={styles.viewRowListItem}>
                      <View
                        style={{
                          flex: 0.693,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}>
                          {totalTaxableIncomeAcquisti == 0
                            ? null
                            : totalTaxableIncomeAcquisti.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 0.687,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}></Text>
                      </View>
                      <View
                        style={{
                          flex: 0.695,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}>
                          {totalVatAmountAcquisti == 0
                            ? null
                            : totalVatAmountAcquisti.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 0.693,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}>
                          {totalNondeductibleVatAcquisti == 0
                            ? null
                            : totalNondeductibleVatAcquisti.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.flexCommon,
                      {
                        borderTopWidth: "none",
                        borderRightWidth: "1px",
                        flex: 0.68,
                        alignItems: "none"
                      },
                    ]}
                  >
                    <Text style={[styles.totalText, { textAlign: "right" }]}>
                      {totalAcquistiAmt == 0
                        ? null
                        : totalAcquistiAmt.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* Table 3 */}

          {rvrseAcquistiRows && rvrseAcquistiRows.length > 0 && (
            <View>
              <View>
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "brown",
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {t("regitriIvaAquistiReverseCharge")}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ marginBottom: 24 }}>
                <TableHeaderRvrseAcquistiRows />
                <View style={styles.viewBottomBorder}>
                  {rvrseAcquistiRows &&
                    rvrseAcquistiRows.map((item, index) => {
                      return (
                        <RegistriIVATable
                          registrationDate={dayjs(
                            item?.registrationDate
                          ).format("DD/MM/YYYY")}
                          invoiceNo={item.invoiceNo}
                          documentDate={dayjs(item.documentDate).format(
                            "DD/MM/YYYY"
                          )}
                          supplier={`${item?.supplierName} - ${item?.supplierVatNo}`}
                          vats={item.vats}
                          total={
                            item.total
                              ? item?.total?.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : null
                          }
                          rvrseAcquistiRows={rvrseAcquistiRows}
                        />
                      );
                    })}
                </View>

                <View style={styles.totalView}>
                  <View style={{ flex: 0.7 }}>
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View style={{ flex: 0.7 }}>
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View style={{ flex: 0.7 }}>
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View style={{ flex: 0.7 }}>
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View
                    style={{
                      flex: 3.065,
                      border: "none",
                      justifyContent: "right",
                    }}
                  >
                    <Text style={styles.totalText}>totale</Text>
                  </View>

                  <View style={[styles.viewRowList, { flex: 2.724 }]}>
                    <View style={styles.viewRowListItem}>
                      <View
                        style={{
                          flex: 0.698,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}>
                          {totalRvrseTaxableIncomeAcquisti == 0
                            ? null
                            : totalRvrseTaxableIncomeAcquisti.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 0.7,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}></Text>
                      </View>
                      <View
                        style={{
                          flex: 0.695,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}>
                          {totalRvrseVatAmountAcquisti == 0
                            ? null
                            : totalRvrseVatAmountAcquisti.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 0.695,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}>
                          {totalRvrseNondeductibleVatAcquisti == 0
                            ? null
                            : totalRvrseNondeductibleVatAcquisti.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 0.68,
                      border: "1px solid black",
                      borderTop: "none",
                    }}
                  >
                    <Text style={styles.totalText}>
                      {totalRvrseAcquistiAmt == 0
                        ? null
                        : totalRvrseAcquistiAmt.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* Table 4 */}

          {rvrseVenditaRows && rvrseVenditaRows.length > 0 && (
            <View>
              <View>
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "brown",
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {t("registriIvaVenditeReverseCharge")}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ marginBottom: 24 }}>
                <TableHeaderRvrseVenditaRows />
                <View style={styles.viewBottomBorder}>
                  {rvrseVenditaRows &&
                    rvrseVenditaRows.map((item, index) => {
                      return (
                        <RegistriIVATable
                          registrationDate={dayjs(
                            item?.registrationDate
                          ).format("DD/MM/YYYY")}
                          invoiceNo={item.invoiceNo}
                          documentDate={dayjs(item.documentDate).format(
                            "DD/MM/YYYY"
                          )}
                          protocol={item.protocol}
                          supplier={`${item?.supplierName} - ${item?.supplierVatNo}`}
                          vats={item.vats}
                          total={
                            item.total
                              ? item?.total?.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : null
                          }
                          rvrseVenditaRows={rvrseVenditaRows}
                        />
                      );
                    })}
                </View>

                <View style={styles.totalView}>
                  <View style={{ flex: 0.7 }}>
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View style={{ flex: 0.7 }}>
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View style={{ flex: 0.7 }}>
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View style={{ flex: 0.7 }}>
                    <Text style={styles.totalText}></Text>
                  </View>
                  <View style={{ flex: 3.21 }}>
                    <Text style={styles.totalText}>totale</Text>
                  </View>

                  <View style={[styles.viewRowList, { flex: 2.793 }]}>
                    <View style={styles.viewRowListItem}>
                      <View
                        style={{
                          flex: 0.697,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}>
                          {totalRvrseTaxableIncomeSales == 0
                            ? null
                            : totalRvrseTaxableIncomeSales.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 0.697,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}></Text>
                      </View>

                      <View
                        style={{
                          flex: 0.698,
                          border: "1px solid black",
                          borderTop: "none",
                          borderRight: "none",
                        }}
                      >
                        <Text style={styles.totalText}>
                          {totalRvrseVatAmountSales == 0
                            ? null
                            : totalRvrseVatAmountSales.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 0.698,
                      border: "1px solid black",
                      borderTop: "none",
                    }}
                  >
                    <Text style={styles.totalText}>
                      {totalRvrseSalesAmt == 0
                        ? null
                        : totalRvrseSalesAmt.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          <View
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
            }}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 12 }}>
                Pagina {pageNumber} di {totalPages} -{" "}
                {dayjs(selectedDate).format("YYYY")}
              </Text>
            )}
            fixed
          />
        </Page>
      </Document>
    ).toBlob();
    saveAs(blob, "Registri_IVA");
  };

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "48px" }}>
        <Box>
          <Typography variant="h3">{t("registriIVA")}</Typography>
          <Divider />
        </Box>
        <Paper sx={{ width: "100%", mt: 4, padding: 2 }}>
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                borderCollapse: "separate",
                borderSpacing: "0px 24px",
              }}
              aria-labelledby="tableTitle"
              size="small"
            >
              <TableBody>
                <TableRow m={1}>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography mr={2}>{t("periodo")}</Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={
                        itIT.components.MuiLocalizationProvider.defaultProps
                          .localeText
                      }
                      adapterLocale={"it"}
                    >
                      <DemoContainer
                        components={["DatePicker", "DatePicker", "DatePicker"]}
                      >
                        <DatePicker
                          slotProps={{ textField: { size: "small" } }}
                          label={t("periodo")}
                          views={["month", "year"]}
                          value={dayjs(selectedDate)}
                          onChange={(newValue) => {
                            const val = dayjs(newValue).format("YYYY-MM");
                            setSelectedDate(val);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ mt: 3 }}>
                  <TableCell
                    sx={{ borderBottom: "none", padding: "0", width: "100%" }}
                  >
                    <FormGroup>
                      <FormLabel>{t("registri")}</FormLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={venditaChecked}
                            onChange={(event) => {
                              const newValue = event.target.checked ? 1 : 0;
                              setVenditaChecked(newValue);
                            }}
                          />
                        }
                        label={t("fattureVendita")}
                        htmlFor="vendita-checkbox" // Add this line
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={acquistiChecked}
                            onChange={(event) => {
                              const newValue = event.target.checked ? 1 : 0;
                              setAcquistiChecked(newValue);
                            }}
                          />
                        }
                        label={t("fattureAcquisti")}
                        htmlFor="acquisti-checkbox"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rvrseVenditaChecked}
                            onChange={(event) => {
                              const newValue = event.target.checked ? 1 : 0;
                              setRvrseVenditaChecked(newValue);
                            }}
                          />
                        }
                        label={t("fattureVenditaReverseCharge")}
                        htmlFor="fattureVenditaReverseCharge-checkbox"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rvrseAcquistiChecked}
                            onChange={(event) => {
                              const newValue = event.target.checked ? 1 : 0;
                              setRvrseAcquistiChecked(newValue);
                            }}
                          />
                        }
                        label={t("fattureAcquistiReverseCharge")}
                        htmlFor="fattureAcquistiReverseCharge-checkbox"
                      />
                    </FormGroup>
                  </TableCell>
                </TableRow>

                <TableRow m={1}>
                  <TableCell sx={{ borderBottom: "none", padding: "0" }}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<LocalPrintshopIcon />}
                      disabled={
                        !selectedDate ||
                        (!venditaChecked &&
                          !acquistiChecked &&
                          !rvrseVenditaChecked &&
                          !rvrseAcquistiChecked)
                      }
                      onClick={() => callApiAndPdf("doc name")}
                    >
                      {t("stampa")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
